require("lightslider");

$(document).ready(function($) {
  $('#quick-static-tile-slider').lightSlider({
    slideMove: 3,
    easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    speed: 200,
    pager: false,
    controls: false,
    enableTouch: true,
    autoWidth: true,
    onSliderLoad: function(el) {
      $('#quick-static-tile-slider').removeClass('cS-hidden');
    }
  });

  $('#quick-tile-slider').lightSlider({
    slideMove: 3,
    easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    speed: 200,
    pager: false,
    controls: false,
    enableTouch: true,
    autoWidth: true,
    onSliderLoad: function() {
      $('#quick-tile-slider').removeClass('cS-hidden');
    }
  });

  $('#quick-tile-slider').on('click', 'a.btn-tile', function(e) {
    var terms;
    terms = $('#search-terms').typeahead('val');
    terms += " " + $(e.target).text();
    $('#search-terms').typeahead('val', terms);
    Cookies.set('last_query', terms, {
      expires: 7
    });
    return lcoation.reload();
  });
  
  $('#quick-category-slider').lightSlider({
    slideMove: 3,
    easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    speed: 200,
    pager: false,
    controls: false,
    enableTouch: true,
    autoWidth: true,
    onSliderLoad: function() {
      $('#quick-category-slider').removeClass('cS-hidden');
    }
  });

  $('#quick-pill-slider').lightSlider({
    slideMove: 3,
    easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
    speed: 200,
    pager: false,
    controls: false,
    enableTouch: true,
    autoWidth: true,
    onSliderLoad: function() {
      $('#quick-pill-slider').removeClass('cS-hidden');
    }
  });

  $('.filter-group input:checkbox:checked').each(function() {
    var pill;
    pill = $('#quick-pill-slider a[data-filter="' + $(this).attr('id') + '"]');
    pill.addClass('btn-pill-active');
    if (pill.index("fa-times-circle") < 0) {
      pill.append(' <i class="fas fa-times-circle"></i>');
    }
  });
  
  $('#quick-pill-slider a').click(function() {
    var cb;
    cb = $("#" + $(this).data('filter'));
    cb.prop("checked", !cb.prop("checked"));
    $('#filter-form').submit();
  });
});